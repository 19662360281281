import { LayoutTypeEnum, useAppSelector } from '@graphstax/redux';
import { CreateNFT, CreateNFTRetro, RootLayout } from '@graphstax/ui';
import { NextSeo } from 'next-seo';
import { NextPageWithLayout } from '../types';

const CreateExperimentPage: NextPageWithLayout = () => {
  const { layoutType } = useAppSelector((state) => state.layout);
  // render retro layout
  if (layoutType === LayoutTypeEnum.RETRO) {
    return (
      <>
        <NextSeo
          title="Execute Inference"
          description="SR Autograph - Permion Product"
        />
        <CreateNFTRetro />
      </>
    );
  }
  // render default create NFT component
  return (
    <>
      <NextSeo
        title="Execute Inference"
        description="SR Autograph - Permion Product"
      />
      <CreateNFT />
    </>
  );
};

CreateExperimentPage.getLayout = function getLayout(page) {
  return <RootLayout>{page}</RootLayout>;
};

export default CreateExperimentPage;
